<template>
    <div class="animated fadeIn">
        <b-card class="card-border mt-4">
            <b-card-title><i class="icon-graph"></i> Inventory Scan Progress Report</b-card-title>
            <b-card-sub-title>Summary of monthly scan progress per region, area and company</b-card-sub-title>
            <div fluid class="px-2 mt-4">
                <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

                <b-card>
                    <b-row>
                        <b-col md="12" sm="12" class="mt-1 mb-2">
                            <b>FILTER OPTIONS</b>
                        </b-col>
                    </b-row>

                    <b-row no-gutters>
                        <b-col lg="4" md="10" sm="12" class="mr-4">
                            <b-form-group label="Date From">
                                <b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                    }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                <span v-show="errors.has('Date From')" class="help-block">
                                    {{ errors.first('Date From') }}
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" md="10" sm="12" class="mr-4">
                            <b-form-group label="Date To">
                                <b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en" reset-button
                                    label-reset-button="Clear" :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                    }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                <span v-show="errors.has('Date To')" class="help-block">
                                    {{ errors.first('Date To') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row no-gutters>
                        <b-col lg="4" md="10" sm="12" class="mr-4">
                            <b-form-group label="Group By">
                                <v-select name="Group By" class="style-chooser" label="text"
                                    :options="filterByOptions.groupByItems" :reduce="(groupBy) => groupBy.value"
                                    v-model="filterBy.groupBy" v-validate="'required'">
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for
                                            <em>
                                                <strong>{{ search }}</strong>
                                            </em>
                                        </template>
                                        <em :style="{ opacity: 0.5 }" v-else>
                                            Start typing to search for group by
                                        </em>
                                    </template>
                                </v-select>
                                <span v-show="errors.has('Group By')" class="help-block">
                                    {{ errors.first('Group By') }}
                                </span>
                            </b-form-group>
                        </b-col>

                        <b-col lg="4" md="10" sm="12" v-if="filterBy.groupBy === 'Per Area'">
                            <b-form-group label="Region"
                                description="Select the region of areas you want to check for progress">
                                <v-select name="Region" class="style-chooser" label="text"
                                    :options="filterByOptions.regionItems" :reduce="(region) => region.value"
                                    v-model="filterBy.region" v-validate="'selectRequired'">
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for
                                            <em>
                                                <strong>{{ search }}</strong>
                                            </em>
                                        </template>
                                        <em :style="{ opacity: 0.5 }" v-else>
                                            Start typing to search for a region
                                        </em>
                                    </template>
                                </v-select>
                                <span v-show="errors.has('Region')" class="help-block">
                                    {{ errors.first('Region') }}
                                </span>
                            </b-form-group>
                        </b-col>

                        <b-col lg="4" md="10" sm="12" v-if="filterBy.groupBy === 'Per Company'">
                            <b-form-group label="Company" description="Select the company you want to check for progress">
                                <v-select name="Company" class="style-chooser" label="text"
                                    :options="filterByOptions.companyItems" :reduce="(company) => company.value"
                                    v-model="filterBy.company" v-validate="'selectRequired'">
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            No results found for
                                            <em>
                                                <strong>{{ search }}</strong>
                                            </em>
                                        </template>
                                        <em :style="{ opacity: 0.5 }" v-else>
                                            Start typing to search for a company
                                        </em>
                                    </template>
                                </v-select>
                                <span v-show="errors.has('Company')" class="help-block">
                                    {{ errors.first('Company') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row no-gutters>
                        <b-col sm="12">
                            <b-button class="mr-1" variant="success" @click="onGenerate">
                                Generate
                            </b-button>
                            <b-button class="mr-1" variant="primary" @click="resetFilters">
                                Reset
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>

                <div v-show="chart !== null">
                    <div class="btn-table-options">
                        <b-row>
                            <b-col sm="6" md="3">
                                <b-dropdown text=" Select Actions " variant="dark" slot="append">
                                    <b-dropdown-item @click="exportToPDF">
                                        Export Report to PDF
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="exportToPNG">
                                        Export Report to PNG
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </div>
                    <b-card ref="exportContent">
                        <b-card-title>{{ chartTitle }}</b-card-title>
                        <b-card-sub-title>{{ chartSubtitle }}</b-card-sub-title>
                        <b-container fluid class="mt-4">
                            <div id="chart-container" class="chart-container">
                                <canvas id="scan-progress-chart"></canvas>
                            </div>
                        </b-container>
                    </b-card>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import reportApi from '@/api/reportApi';

// Others
import Chart from 'chart.js';
import config from '@/config/env-constants';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import JsonExcel from 'vue-json-excel';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'inventory-scan-progress-report',
    components: { JsonExcel, Loading },
    data() {
        return {
            defaultFilterBy: {
                dateFrom: null,
                dateTo: null,
                groupBy: 'Per Region',
                company: { ...config.companyDefaultValue },
                region: { ...config.dropdownDefaultValue },
            },
            filterBy: {
                dateFrom: null,
                dateTo: null,
                groupBy: 'Per Region',
                company: { ...config.companyDefaultValue },
                region: { ...config.dropdownDefaultValue },
            },
            prevFilterBy: null,

            filterByOptions: {
                companyItems: [],
                groupByItems: [
                    { text: ' - Please select - ', value: null },
                    { text: 'Per Region', value: 'Per Region' },
                    { text: 'Per Area', value: 'Per Area' },
                    { text: 'Per Company', value: 'Per Company' },
                ],
                regionItems: []
            },

            allRegionsObj: {},
            allCompaniesObj: {},

            chart: null,

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        chartTitle() {
            if (this.filterBy.groupBy === 'Per Area') {
                return 'Inventory Scans ' + this.filterBy.groupBy + ' of ' + this.filterBy.region;
            }
            return 'Inventory Scans ' + this.filterBy.groupBy;
        },
        chartSubtitle() {
            return DateUtil.getFormattedDate(this.filterBy.dateFrom) + ' to ' + DateUtil.getFormattedDate(this.filterBy.dateTo);
        }
    },
    watch: {
        "filterBy.groupBy": function () {
            this.filterBy.company = { ...config.companyDefaultValue };
            this.filterBy.region = { ...config.dropdownDefaultValue };
        }
    },
    mounted() {
        setTimeout(() => {
            try {
                // Don't initiate data retrieval when the account is not authenticated
                if (!this.$store.getters.isAuthenticated) {
                    return;
                }

                // show loading indicator
                this.isLoading = true;

                this.allCompaniesObj = this.$store.getters.companies;
                this.filterByOptions.companyItems = DropDownItemsUtil.retrieveCompanyItems(this.allCompaniesObj);

                this.allRegionsObj = this.$store.getters.regions;
                this.filterByOptions.regionItems = DropDownItemsUtil.retrieveRegionItems(this.allRegionsObj);

                this.resetFilters();
            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            }

            // hide loading indicator
            this.isLoading = false;

        }, config.timeout);
    },
    methods: {
        resetFilters() {
            this.chart = null;
            this.filterBy = Object.assign({}, this.defaultFilterBy);

            // reset validation
            this.$validator.reset();
            this.errors.clear();
        },

        dateFromDisabled(_ymd, date) {
            return date > new Date();
        },
        async validateFilter() {
            let isValid = await this.$validator.validateAll();

            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                return isValid;
            }

            if (
                (_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
                (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))
            ) {
                this.$toaster.warning(
                    'Invalid Date Range. Date From and Date To must both have value.'
                );
                isValid = false;
            } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
                this.$toaster.warning(
                    'Invalid Date Range. Date From must be less than Date To.'
                );
                isValid = false;
            } else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 100) {
                this.$toaster.warning(
                    'Invalid Date Range. Data range is allowed up to 100 days difference.'
                );
                isValid = false;
            } else if (this.filterBy.groupBy === 'Per Area' && (this.filterBy.region === null || this.filterBy.region.value === null)) {
                this.$toaster.warning(
                    'Region is required.'
                );
                isValid = false;
            } else if (this.filterBy.groupBy === 'Per Company' && this.filterBy.company.id === null) {
                this.$toaster.warning(
                    'Company is required.'
                );
                isValid = false;
            }

            return isValid;
        },
        getReportParameters() {
            let filter = Object.assign({}, this.filterBy);
            filter.companyId = this.loggedUserCompany.id;

            if (this.filterBy.dateFrom) {
                filter.fromTimestamp = DateUtil.startDateTimeStamp(
                    new Date(this.filterBy.dateFrom)
                );
            }
            if (this.filterBy.dateTo) {
                filter.toTimestamp = DateUtil.endDateTimeStamp(
                    new Date(this.filterBy.dateTo)
                );
            }

            return filter;
        },
        async onGenerate() {
            let isValid = await this.validateFilter();
            if (!isValid) {
                return;
            }

            // Reset chart
            this.chart = null;
            // Show loader
            this.isLoading = true;

            try {
                let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
                let reportParam = this.getReportParameters();
                let { data } = await reportApi.getInventoryScanProgressReport(
                    reportParam,
                    view,
                    this.loggedUser.id
                );

                if (data.isSuccess) {
                    let labels = data.labels;
                    let datasets = data.datasets;

                    if (_.isEmpty(datasets)) {
                        this.$toaster.warning('No Results found.');
                    } else {
                        this.onGenerateChart(labels, datasets);
                    }

                    // Hide loader
                    this.isLoading = false;
                    // Mark the prevFilter
                    this.prevFilter = Object.assign({}, this.filterBy);
                } else {
                    this.$toaster.error(
                        'Error loading data. Please reload the page again.'
                    );
                    // hide loading indicator
                    this.isLoading = false;
                }
            } catch (error) {
                this.$toaster.error(
                    'Error loading data. Please reload the page again.'
                );

                // hide loading indicator
                this.isLoading = false;
            }
        },
        onGenerateChart(labels, datasets) {
            const chartContainer = document.getElementById('chart-container');
            chartContainer.innerHTML = '<canvas id="scan-progress-chart"></canvas>';

            const ctx = document.getElementById('scan-progress-chart');
            this.chart = new Chart(ctx, {
                type: 'bar',
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    legend: {
                        position: 'bottom',
                    },
                    responsive: true,
                    maintainAspectRatio: true
                },
                data: {
                    labels: labels,
                    datasets: datasets
                }
            });
        },

        async exportToPDF() {
            const content = this.$refs.exportContent;

            const canvas = await html2canvas(content);
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF({
                orientation: "landscape"
            });
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();

            let fileName = this.chartTitle + " (" + this.filterBy.dateFrom + " to " + this.filterBy.dateTo + ").pdf";
            pdf.addImage(imgData, 'PNG', 0, 0, width, height);
            pdf.save(fileName);
        },
        async exportToPNG() {
            const content = this.$refs.exportContent;

            const canvas = await html2canvas(content);
            const pngImage = canvas.toDataURL('image/png');

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = pngImage;

            let fileName = this.chartTitle + " (" + this.filterBy.dateFrom + " to " + this.filterBy.dateTo + ").png";
            link.download = fileName;
            link.click();
        },
    }
}

</script>

<style scoped></style>